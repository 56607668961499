import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalContextProvider from './context/GlobalContextProvider';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { SignedOut } from "./components/Pages/SignedOut";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

export default ({ element }) => (
  <MsalProvider instance={msalInstance}>
    <GlobalContextProvider>
      <AuthenticatedTemplate>
        {element}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignedOut />
      </UnauthenticatedTemplate>
    </GlobalContextProvider>
  </MsalProvider>
);
