import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import styled from '@emotion/styled';

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

const Button = styled.button`
  background-color: #DC4234;
  border: 0;
  color: #ffffff;
  display: block;
  font-family: 'Yantramanav',Helvetica,Arial,Lucida,sans-serif;
  font-size: 16px;
  margin: 2rem auto 0;
  padding: 10px 35px;
  position: relative;
  transition: all 300ms ease 0ms;

  &:hover {
    background-color: #b71b0e;
  }
`;

export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button variant="secondary" className="ml-auto" onClick={() => handleLogin(instance)}>Sign in</Button>
    );
}
