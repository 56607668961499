export const msalConfig = {
  auth: {
    clientId: process.env.GATSBY_MS_AUTH_CLIENT_ID,
    authority: process.env.GATSBY_MS_AUTH_CLIENT_AUTHORITY,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};
