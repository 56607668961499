import React from 'react';
import DarkThemeSVG from './DarkThemeSVG';

const ItsJuiceSignIn = () => (
  <DarkThemeSVG viewBox="0 0 340.2 77.6">
    <g>
      <path d="M206.7,63.6c9.3,0,13.8-5.1,15.2-12.4h18c-3,15.5-13.6,26.3-33.1,26.3c-22.8,0-37.4-15-37.4-37.8
        C169.3,17,183.9,0,206.7,0c19.6,0,30.1,10.7,33.1,26.3h-18c-1.4-7.2-5.9-12.4-15.2-12.4c-12.1,0-18.6,10.3-18.6,25.8
        C188.1,55.2,194.6,63.6,206.7,63.6z"/>
      <g>
        <path d="M0,24.5h15.5V40H0V24.5z"/>
        <path d="M36.4,2h16.4v47c0,17-10.2,27.9-27.1,27.9C10.3,76.9,0.1,67.5,0,51.6h15.5c0.1,8.3,4.1,12.5,10.3,12.5
          c7.2,0,10.7-5,10.7-14.5V2z"/>
      </g>
      <path d="M140.9,2h17.7v73.6h-17.7V2z"/>
      <path d="M96.6,76.9c-20.1,0-32.1-10.6-32.1-30.7V2H82v43.8c0,10.5,3.9,17.3,14.6,17.3c10.8,0,14.7-6.9,14.7-17.3V2
        h17.4v44.4C128.7,66.3,116.9,76.9,96.6,76.9z"/>
      <path d="M324.7,60.1h15.5v15.5h-15.5V60.1z"/>
      <g>
        <path d="M318.5,37.3c0-10.1-3-19.4-8.6-25.9C303.7,3.9,294.6,0,283.6,0c-22.2,0-37.7,16-37.7,38.8
          c0,23.1,15.3,38.7,38.1,38.7c16.7,0,29.3-8.8,33.5-22.4H299c-2.5,5.2-8.3,8-15,8c-11.4,0-17.6-7.4-19-19.6h53.5V37.3z M265,30.3
          c0-9.4,7.9-17,17.7-17s17.7,7.6,17.7,17H265z"/>
      </g>
    </g>
  </DarkThemeSVG>
);

export default ItsJuiceSignIn;
