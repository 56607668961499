import React from "react";
import { SignInButton } from "./../Buttons/SignInButton";
import styled from '@emotion/styled';
import { Global } from '@emotion/core';
import { Styled } from 'theme-ui';
import { globalStyles } from './../../styles';
import PageBackground from './../../sign-in-background.png';
import ItsJuiceSignIn from '../icons/ItsJuiceSignIn';
import mediaqueries from './../../styles/media';

const StyledBodyWrapper = styled.section`
  background-image: url(${PageBackground});
  background-color: #F2F2F2;
  display: flex;
  min-height: 100vh;
`;

const StyledLogoContainer = styled.div`
  margin: auto;

  svg {
    display: block;
    max-width: 100%;
    width: 30rem;
    ${mediaqueries.tablet`
      width: 100%;
    `};
  }

  p {
    font-size: 5rem;
    color: #fff;
    text-align: center;
    ${mediaqueries.tablet`
      font-size: 3rem;
    `};
  }
`;

export const SignedOut = () => {
  return (
    <Styled.root>
      <Global styles={globalStyles} />
      <StyledBodyWrapper>
        <StyledLogoContainer>
          <ItsJuiceSignIn />
          <p>HANDBOOK</p>
          <SignInButton />
        </StyledLogoContainer>
      </StyledBodyWrapper>
    </Styled.root>
  );
}
