import styled from '@emotion/styled';
import React from 'react';

const DarkThemeSVG = ({ children, viewBox }) => (
  <DarkThemeStyledSVG xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
    {children}
  </DarkThemeStyledSVG>
);

const DarkThemeStyledSVG = styled.svg`
  fill: #fff;
`;

export default DarkThemeSVG;
